import * as React from "react";
import "./BubblesBgHeader.scss";

const BubblesBgHeader = ({ text }) => {
  return (
    <div className="bubbles-bg">
      <div className="fixed-image-block py-5">
        <div className="content-box container">
          <div className="row">
            <h1 className="col-12 d-flex justify-content-center">{text}</h1>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BubblesBgHeader;
