import * as React from "react";
import "./ProgramCard.scss";

const ProgramCard = ({ data, h100 }) => {
  const h100Class = h100 ? "h-100" : "";
  return (
    <div className={`programs-card mb-4 ${h100Class}`}>
      <div
        className="col"
        dangerouslySetInnerHTML={{ __html: data?.text }}
      ></div>
    </div>
  );
};
export default ProgramCard;
