import React, { useState, useEffect } from "react";
import "../scss/ProgramsPage.scss";
import Seo from "../components/seo/seo";

import BubblesBgHeader from "../shared/bubbles-bg-header/BubblesBgHeader";
import ProgramCard from "../shared/program-card/ProgramCard";

import axios from "../http/axios";

const ProgramsPage = () => {
  const [programs, setPrograms] = useState([]);

  useEffect(() => {
    axios.get(`/programs`).then((res) => {
      setPrograms(res.data);
    });
  }, []);
  return (
    <div className="programs-page">
      <Seo title="Programs -" />
      <BubblesBgHeader text="Programs" />
      <div className="content-block container mt-5">
        <div className="row">
          <h2 className="col progs-list-title mb-4">
            There are 2 different swim options to choose from:
          </h2>
        </div>
        <div className="row mb-3">
          <div className="col disclaimer">
            **Please Note: All Immersions** Difficulty or ease of skills taught
            depends on the level of each individual swimmer in the class. It is
            not uncommon to have a mix of beginners and those who already know
            how to swim in the same class regardless of age.
          </div>
        </div>
        <div className="row mb-6">
          <div className="first-prog-wrapper col-lg-6">
            <ProgramCard data={programs[0]} h100={true} />
          </div>
          <div className="col-lg-6 overflow-hidden">
            <div className="row">
              <div className="col-lg-12">
                <ProgramCard data={programs[1]} />
              </div>
            </div>
            <div className="row h-100">
              <div className="col-lg-12">
                <ProgramCard data={programs[2]} h100={true} />
              </div>
            </div>
          </div>
        </div>
      </div>
      &nbsp;
    </div>
  );
};

export default ProgramsPage;
